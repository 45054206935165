import React from 'react';

import Panel from 'components/Panel';
export default function Home() {
    return (
        <Panel title="Accueil">
            <div>
                Bienvenue sur la page d&apos;administration de NauticSpot.
            </div>
        </Panel>
    );
}
