import React, { useState } from 'react';
import { Row, Col } from 'antd';

import ResetPasswordForm from 'components/forms/ResetPasswordForm.jsx';
import PasswordService from 'services/PasswordService';
import ToastActions from 'actions/ToastActions';

function getUrlParameter(search, name) {
    const n = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${n}=([^&#]*)`);
    const results = regex.exec(search);
    return results === null
        ? ''
        : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export default function ResetPassword({ location, history }) {
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState([]);

    const email = getUrlParameter(location.search, 'email');
    const validationCode = getUrlParameter(location.search, 'validation-code');

    function resetPassword({ plainPassword }) {
        setLoading(true);
        PasswordService.patchPassword({
            email,
            validationCode,
            plainPassword,
        })
            .then(() => {
                history.push('/login');
            })
            .catch(handleError)
            .finally(() => {
                setLoading(false);
            });
    }

    function handleError(err) {
        try {
            const resp = JSON.parse(err.response);
            ToastActions.createToastError(resp.message);
        } catch (e) {
            ToastActions.createToastError('Une erreur est survenue');
        }
    }

    return (
        <Row
            type="flex"
            align="middle"
            justify="center"
            className="login-page"
            style={{ backgroundImage: 'url("images/login-background.jpg")' }}
        >
            <Col xs={14} sm={8} md={6} lg={4} xl={4}>
                <img
                    src="images/logo.png"
                    alt="Logo Nauticspot"
                    style={{ width: '100%', paddingBottom: '1rem' }}
                />
                <h2>Modifier mon mot de passe</h2>
                <ResetPasswordForm
                    onChange={setFields}
                    fields={fields}
                    onSubmit={resetPassword}
                    loading={loading}
                />
            </Col>
        </Row>
    );
}
