import ServiceBase from 'services/ServiceBase';
import BaseUrlConstants from 'constants/BaseUrlConstants';

const URL: string = `${BaseUrlConstants.BASE_URL}harbours`;

class HarbourModuleService extends ServiceBase {
    constructor() {
        super(URL);
    }

    patchHarbourModules(harbourId: number, modules: any) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/modules`,
            method: 'PATCH',
            data: modules,
        });
    }
}

export default new HarbourModuleService();
