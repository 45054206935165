import React from 'react';

interface Props {
    children?: any;
    className?: string;
    title: string;
}

export default function Panel({ children, className, title, ...props }: Props) {
    return (
        <div className={`layout-panel ${className || ''}`} {...props}>
            {title && <h2 className="panel-title">{title}</h2>}
            <div className="panel-content">{children}</div>
        </div>
    );
}
