import React from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import ScrollArea from 'react-scrollbar';

import { Button, Menu, Layout, Popover, Avatar } from 'antd';

import {
    HomeOutlined,
    TeamOutlined,
    BarChartOutlined,
    SwapOutlined,
    LogoutOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';

import Locale from 'locale/LocaleFactory';
import SecurityService from 'services/SecurityService';

import LoginActions from 'actions/LoginActions';
import LoginStore from 'stores/LoginStore';

import Home from 'pages/Home';
import HarbourList from 'pages/HarbourList';
import Reporting from 'pages/Reporting';
import OutingList from 'pages/OutingList';
import Tutorials from 'pages/tutorial/Tutorials';
import UserList from 'pages/user/UserList';

const { Header, Content, Sider } = Layout;

export default class AppLayout extends React.Component {
    constructor() {
        super();
        this.state = {
            menuCollapsed: localStorage.getItem('sidebar-collapsed') === 'true',
        };
    }

    logout = () => {
        LoginActions.logoutUser();
        this.props.history.push('/login');
    };

    handleMenuClick = (e) => {
        if (e.key !== this.props.location.pathname) {
            this.props.history.push(e.key);
        }
    };

    onCollapse = (collapsed) => {
        this.setState({
            menuCollapsed: collapsed,
        });
        localStorage.setItem('sidebar-collapsed', collapsed.toString());
    };

    render() {
        const user = LoginStore.getUser();

        if (!SecurityService.isGranted(['SuperAdmin'])) {
            return (
                <div>
                    <h1>Non autorisé</h1>
                    <p>
                        Cette partie de l&apos;application est réservée aux
                        administrateurs.
                        <br />
                        Si vous pensez que c&apos;est une erreur, veuillez contacter nauticspot !
                    </p>
                    <Button icon={<LogoutOutlined />} onClick={this.logout}>
                        {Locale.trans('login.logOut.button')}
                    </Button>
                </div>
            );
        }

        const selectedMenu = this.props.location.pathname;

        const smallLogoUrl = 'images/logo-icon.png';
        const logoUrl = 'images/logo-text.png';

        const firstname = user.firstName.replace(
            /\w\S*/g,
            (tStr) =>
                tStr.charAt(0).toUpperCase() + tStr.substr(1).toLowerCase(),
        );
        const fullName = `${firstname} ${user.lastName.toUpperCase()}`;

        return (
            <Layout style={{ minHeight: '100vh' }}>
                <Sider
                    collapsible
                    collapsed={this.state.menuCollapsed}
                    onCollapse={this.onCollapse}
                >
                    <ScrollArea horizontal={false}>
                        <Link to="/">
                            <div className="logo">
                                <img
                                    className="logo-icon"
                                    alt="Accueil"
                                    src={smallLogoUrl}
                                />
                                <img
                                    className="logo-text"
                                    alt="Accueil"
                                    src={logoUrl}
                                />
                            </div>
                        </Link>

                        <Menu
                            theme="dark"
                            onClick={this.handleMenuClick}
                            selectedKeys={[selectedMenu]}
                            mode="inline"
                        >
                            <Menu.Item key="/">
                                <HomeOutlined />
                                <span>Accueil</span>
                            </Menu.Item>
                            <Menu.Item key="/harbours">
                                <TeamOutlined />
                                <span>Ports</span>
                            </Menu.Item>
                            <Menu.Item key="/reporting">
                                <BarChartOutlined />
                                <span>Reporting</span>
                            </Menu.Item>
                            <Menu.Item key="/outings">
                                <SwapOutlined />
                                <span>Sorties</span>
                            </Menu.Item>
                            <Menu.Item key="/tutorials">
                                <QuestionCircleOutlined />
                                <span>Tutoriels</span>
                            </Menu.Item>
                            <Menu.Item key="/users">
                                <TeamOutlined />
                                <span>Utilisateurs</span>
                            </Menu.Item>
                        </Menu>
                    </ScrollArea>
                </Sider>
                <Layout>
                    <Header
                        style={{
                            paddingLeft: this.state.menuCollapsed
                                ? '130px'
                                : '250px',
                        }}
                    >
                        <div className="welcome-message">
                            Bonjour {fullName}
                        </div>
                        <Popover
                            overlayClassName="layout-user-menu"
                            content={
                                <React.Fragment>
                                    <Button
                                        icon={<LogoutOutlined />}
                                        onClick={this.logout}
                                    >
                                        {Locale.trans('login.logOut.button')}
                                    </Button>
                                </React.Fragment>
                            }
                            title={null}
                            placement="bottomRight"
                        >
                            <Avatar size="large" className="profile-btn">
                                {user.firstName.charAt(0).toUpperCase()}
                                {user.lastName.charAt(0).toUpperCase()}
                            </Avatar>
                        </Popover>
                    </Header>
                    <Content
                        style={{
                            marginLeft: this.state.menuCollapsed
                                ? '80px'
                                : '200px',
                        }}
                    >
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route
                                exact
                                path="/harbours"
                                component={HarbourList}
                            />
                            <Route
                                exact
                                path="/reporting"
                                component={Reporting}
                            />
                            <Route
                                exact
                                path="/outings"
                                component={OutingList}
                            />
                            <Route
                                exact
                                path="/tutorials"
                                component={Tutorials}
                            />
                            <Route exact path="/users" component={UserList} />
                        </Switch>
                    </Content>
                </Layout>
            </Layout>
        );
    }
}
