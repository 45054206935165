// @flow

import React from 'react';
import { FilterOutlined } from '@ant-design/icons';

interface Props {
    active: boolean;
    onClick?: () => void;
}

export default function FilterIcon({ active, onClick }: Props) {
    return (
        <FilterOutlined
            style={{
                color: active ? '#108ee9' : '#aaa',
            }}
            onClick={onClick}
        />
    );
}
