import Login from 'constants/LoginConstants';
import BaseStore from 'stores/BaseStore';

class LoginStore extends BaseStore {
    _user: User | null | undefined;
    _jwt: string | null | undefined;

    constructor() {
        super();
        this.subscribe(() => this._registerToActions.bind(this));
        this._user = null;
        this._jwt = null;
    }

    _registerToActions(action: { type: string; payload: any }): void {
        const { type, payload } = action;
        switch (type) {
            case Login.LOGIN_USER:
                this._jwt = payload.jwt;
                this._user = payload.user;
                this.emitChange();
                break;
            case Login.LOGOUT_USER:
                this._user = null;
                this.emitChange();
                break;
            default:
                break;
        }
    }

    getUser(): User | null | undefined {
        return this._user;
    }

    getJwt(): string | null | undefined {
        return this._jwt;
    }

    isLoggedIn(): boolean {
        return !!this._user;
    }
}

export default new LoginStore();
