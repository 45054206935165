import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';

import OutingForm from 'components/forms/OutingForm.jsx';

import ToastActions from 'actions/ToastActions';

import OutingActions from 'actions/OutingActions';

import BoatActions from 'actions/BoatActions';
import BoatStore from 'stores/BoatStore';

/**
 * The modal to create a new outing.
 */
export default function CreateOutingModal({ harbour, visible, onCancel }) {
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState([]);
    const [boats, setBoats] = useState([]);

    useEffect(() => {
        function receiveBoats() {
            setBoats(BoatStore.getByHarbour(harbour));
        }
        BoatStore.addChangeListener(receiveBoats);
        BoatActions.reloadByHarbour(harbour);
        return () => {
            BoatStore.removeChangeListener(receiveBoats);
        };
    }, [harbour]);

    function handleSubmit(o) {
        const outing = o;

        setLoading(true);
        const { datetimeIn, datetimeOut } = outing;
        outing.datetimeOut = {
            date: {
                year: datetimeOut.year(),
                month: datetimeOut.month() + 1,
                day: datetimeOut.date(),
            },
            time: {
                hour: datetimeOut.hour(),
                minute: datetimeOut.minute(),
            },
        };
        outing.datetimeIn = {
            date: {
                year: datetimeIn.year(),
                month: datetimeIn.month() + 1,
                day: datetimeIn.date(),
            },
            time: {
                hour: datetimeIn.hour(),
                minute: datetimeIn.minute(),
            },
        };

        OutingActions.create(harbour, outing)
            .then(() => {
                setFields([]);
                ToastActions.createToastSuccess('Sortie créée');
                onCancel();
            })
            .catch(handleError)
            .finally(() => {
                setLoading(false);
            });
    }

    function handleError(err) {
        try {
            const resp = JSON.parse(err.response);
            ToastActions.createToastError(resp.message);
        } catch (e) {
            ToastActions.createToastError('Une erreur est survenue');
        }
    }

    return (
        <Modal
            title="Ajouter une Sortie"
            visible={visible}
            onCancel={onCancel}
            footer={null}
        >
            <OutingForm
                boats={boats}
                onChange={setFields}
                fields={fields}
                onSubmit={handleSubmit}
                loading={loading}
            />
        </Modal>
    );
}
