import React, { useState } from 'react';
import { Row, Col } from 'antd';

import ResetPasswordRequestForm from 'components/forms/ResetPasswordRequestForm.jsx';
import PasswordService from 'services/PasswordService';
import ToastActions from 'actions/ToastActions';

export default function ResetPasswordRequest({ history }) {
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState([]);
    const [successMsgVisible, setSuccessMsgVisible] = useState(false);

    function handleSubmit({ email }) {
        setLoading(true);
        setSuccessMsgVisible(false);
        PasswordService.postPasswordRequest({
            email,
        })
            .then(() => {
                setSuccessMsgVisible(true);
                setTimeout(() => {
                    history.push('/login');
                }, 4000);
            })
            .catch(handleError)
            .finally(() => {
                setLoading(false);
            });
    }

    function handleError(err) {
        try {
            const resp = JSON.parse(err.response);
            ToastActions.createToastError(resp.message);
        } catch (e) {
            ToastActions.createToastError('Une erreur est survenue');
        }
    }

    return (
        <Row
            type="flex"
            align="middle"
            justify="center"
            className="login-page"
            style={{ backgroundImage: 'url("images/login-background.jpg")' }}
        >
            <Col xs={14} sm={8} md={6} lg={4} xl={4}>
                <img
                    src="images/logo.png"
                    alt="Logo Nauticspot"
                    style={{ width: '100%', paddingBottom: '1rem' }}
                />
                <h2>Mot de passe oublié</h2>
                {successMsgVisible && (
                    <div className="success-msg">
                        Un email vous a été envoyé pour modifier votre mot de
                        passe.
                    </div>
                )}
                <ResetPasswordRequestForm
                    onChange={setFields}
                    fields={fields}
                    onSubmit={handleSubmit}
                    loading={loading}
                />
            </Col>
        </Row>
    );
}
