import React from 'react';
import Locale from 'locale/LocaleFactory';
import { Popconfirm, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

interface Props {
    onDelete: () => void;
    shape?: string;
    className?: string;
    size?: 'small' | 'large' | undefined;
}

export default function DeleteButton({
    onDelete,
    shape,
    size,
    className,
}: Props) {
    return (
        <Popconfirm
            title={Locale.trans('delete.confirm')}
            onConfirm={onDelete}
            okText={Locale.Popconfirm.okText}
            cancelText={Locale.Popconfirm.cancelText}
        >
            {shape === 'circle' ? (
                <Button
                    type={'danger' as any}
                    shape="circle"
                    icon={<DeleteOutlined />}
                    className={className}
                    size={size}
                />
            ) : (
                <Button
                    type={'danger' as any}
                    className={className}
                    size={size}
                >
                    {Locale.trans('delete')}
                </Button>
            )}
        </Popconfirm>
    );
}
