import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'antd';

import HarbourForm from 'components/forms/HarbourForm.jsx';

import ToastActions from 'actions/ToastActions';

import HarbourActions from 'actions/HarbourActions';
import HarbourModuleService from 'services/HarbourModuleService';

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

function getFieldsFromEntity(entity, prefix) {
    if (!entity) {
        return {};
    }
    const fields = Object.keys(entity).map((k) => {
        const value = entity[k];
        const name = prefix ? [...prefix, k] : [k];
        if (typeof value === 'object') {
            return getFieldsFromEntity(value, name);
        }
        return {
            name,
            value,
        };
    });
    return fields.flat();
}

/**
 * The modal to edit a harbour.
 */
export default function EditHarbourModal({ harbour, visible, onCancel }) {
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState(getFieldsFromEntity(harbour));
    const prevVisible = usePrevious(visible);

    useEffect(() => {
        if (visible && !prevVisible) {
            setFields(getFieldsFromEntity(harbour));
        }
    }, [visible, prevVisible, harbour]);

    function handleSubmit(values) {
        if (!harbour) {
            return;
        }

        const modules = values.modules;
        values.modules = undefined;

        const harbourId = harbour.id;

        setLoading(true);

        Promise.all([
            HarbourModuleService.patchHarbourModules(harbourId, modules),
            HarbourActions.edit(harbourId, values),
        ])
            .then((res) => {
                const newHarbour = res[1];
                setFields([]);
                ToastActions.createToastSuccess(
                    `Port "${newHarbour.name}" modifié`,
                );
                onCancel();
                HarbourActions.reload(true);
            })
            .catch(handleError)
            .finally(() => {
                setLoading(false);
            });
    }

    function handleError(err) {
        try {
            const resp = JSON.parse(err.response);
            ToastActions.createToastError(resp.message);
        } catch (e) {
            ToastActions.createToastError('Une erreur est survenue');
        }
    }

    return (
        <Modal
            title="Modifier un Port"
            visible={harbour && visible}
            onCancel={onCancel}
            footer={null}
        >
            <HarbourForm
                harbour={harbour}
                onChange={setFields}
                fields={fields}
                onSubmit={handleSubmit}
                loading={loading}
            />
        </Modal>
    );
}
