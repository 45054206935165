import React, { useState } from 'react';
import { Modal } from 'antd';

import HarbourForm from 'components/forms/HarbourForm.jsx';

import ToastActions from 'actions/ToastActions';

import HarbourActions from 'actions/HarbourActions';
import HarbourModuleService from 'services/HarbourModuleService';

/**
 * The modal to create a new harbour.
 */
export default function CreateHarbourModal({ visible, onCancel }) {
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState([]);

    function handleSubmit(values) {
        const modules = values.modules;
        values.modules = undefined;

        setLoading(true);
        HarbourActions.create(values)
            .then((newHarbour) => {
                HarbourModuleService.patchHarbourModules(newHarbour.id, modules)
                    .then(() => {
                        HarbourActions.reloadById(newHarbour.id);

                        setFields([]);
                        ToastActions.createToastSuccess(
                            `Port "${newHarbour.name}" créé`,
                        );
                        onCancel();
                    })
                    .catch(handleError)
                    .finally(() => {
                        setLoading(false);
                    });
            })
            .catch(handleError);
    }

    function handleError(err) {
        try {
            const resp = JSON.parse(err.response);
            ToastActions.createToastError(resp.message);
        } catch (e) {
            ToastActions.createToastError('Une erreur est survenue');
        }
    }

    return (
        <Modal
            title="Ajouter un Port"
            visible={visible}
            onCancel={onCancel}
            footer={null}
        >
            <HarbourForm
                onChange={setFields}
                fields={fields}
                onSubmit={handleSubmit}
                loading={loading}
            />
        </Modal>
    );
}
