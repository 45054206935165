import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { Modal } from 'antd';

import OutingForm from 'components/forms/OutingForm.jsx';

import ToastActions from 'actions/ToastActions';

import OutingActions from 'actions/OutingActions';

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

function getFieldsFromEntity(entity) {
    if (!entity) {
        return {};
    }
    let fields = Object.keys(entity).map((k) => {
        let value = entity[k];
        if (value && ['datetimeOut', 'datetimeIn'].indexOf(k) > -1) {
            value = moment(value);
        }

        return {
            name: [k],
            value,
        };
    });

    return fields;
}

/**
 * The modal to edit an outing.
 */
export default function EditOutingModal({
    outing,
    harbour,
    visible,
    onCancel,
}) {
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState(getFieldsFromEntity(outing));

    const prevVisible = usePrevious(visible);

    useEffect(() => {
        if (visible && !prevVisible) {
            setFields(getFieldsFromEntity(outing));
        }
    }, [visible, prevVisible, outing]);

    function handleSubmit(o) {
        if (!o) {
            return;
        }
        const outingId = outing.id;

        setLoading(true);

        const datetimeIn = moment(o.datetimeIn, 'YYYY-MM-DD HH:mm');
        const datetimeOut = moment(o.datetimeOut, 'YYYY-MM-DD HH:mm');
        o.datetimeOut = {
            date: {
                year: datetimeOut.year(),
                month: datetimeOut.month() + 1,
                day: datetimeOut.date(),
            },
            time: {
                hour: datetimeOut.hour(),
                minute: datetimeOut.minute(),
            },
        };
        o.datetimeIn = {
            date: {
                year: datetimeIn.year(),
                month: datetimeIn.month() + 1,
                day: datetimeIn.date(),
            },
            time: {
                hour: datetimeIn.hour(),
                minute: datetimeIn.minute(),
            },
        };

        OutingActions.edit(harbour, outingId, o)
            .then(() => {
                setFields([]);
                ToastActions.createToastSuccess('Sortie modifiée');
                onCancel();
            })
            .catch(handleError)
            .finally(() => {
                setLoading(false);
            });
    }

    function handleError(err) {
        try {
            const resp = JSON.parse(err.response);
            ToastActions.createToastError(resp.message);
        } catch (e) {
            ToastActions.createToastError('Une erreur est survenue');
        }
    }

    return (
        <Modal
            title="Modifier une Sortie"
            visible={outing && visible}
            onCancel={onCancel}
            footer={null}
        >
            <OutingForm
                outing={outing}
                onChange={setFields}
                fields={fields}
                onSubmit={handleSubmit}
                loading={loading}
            />
        </Modal>
    );
}
