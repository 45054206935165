import { useEffect, useState } from 'react';
import HarbourStore from 'stores/HarbourStore';
import HarbourActions from 'actions/HarbourActions';

export default function useHarbours() {
    const [data, setData] = useState([]);

    useEffect(() => {
        function refresh() {
            setData(HarbourStore.getAll());
        }

        refresh();
        HarbourActions.reload();

        HarbourStore.addChangeListener(refresh);
        return () => {
            HarbourStore.removeChangeListener(refresh);
        };
    }, []);

    return data;
}
