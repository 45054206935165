import React, { useState, useCallback } from 'react';

import ServiceBase from 'services/ServiceBase';
import BaseUrlConstants from 'constants/BaseUrlConstants';
import ToastActions from 'actions/ToastActions';
import { Table, Button, Input, Select } from 'antd';
import useHarbours from 'hooks/useHarbours';

import './UserList.scss';
import ArrayService from 'services/utils/ArrayService';
import Locale from 'locale/LocaleFactory';
import LoadingIcon from 'components/LoadingIcon';
import DateService from 'services/utils/DateService';
import StringService from 'services/utils/StringService';

export default function UserList() {
    const [filters, setFilters] = useState({});
    const { data, loading, refetch } = useUsers(filters);

    return (
        <div className="user-list">
            <Filters
                filters={filters}
                onChange={setFilters}
                onSubmit={refetch}
                loading={loading}
            />
            <UserTable data={data} loading={loading} />
        </div>
    );
}

function UserTable({ data, loading }) {
    const harbours = useHarbours();

    const columns = [
        {
            title: 'ID',
            key: 'id',
            dataIndex: 'id',
            sorter: ArrayService.sortNumberColumn('id'),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Prénom',
            key: 'firstName',
            dataIndex: 'firstName',
            sorter: ArrayService.sortStringColumn('firstName'),
        },
        {
            title: 'Nom',
            key: 'lastName',
            dataIndex: 'lastName',
            sorter: ArrayService.sortStringColumn('lastName'),
        },
        {
            title: 'Email',
            key: 'email',
            dataIndex: 'email',
            sorter: ArrayService.sortStringColumn('email'),
        },
        {
            title: 'Téléphone',
            key: 'phone',
            dataIndex: 'phoneNumber',
            sorter: ArrayService.sortStringColumn('phoneNumber'),
        },
        {
            title: 'Catégorie',
            key: 'category',
            render: (u) =>
                u.category &&
                Locale.trans(`accessRequest.categories.${u.category}`),
            sorter: ArrayService.sortStringColumn(
                (u) =>
                    u.category &&
                    Locale.trans(`accessRequest.categories.${u.category}`),
            ),
        },
        {
            title: "Ports d'attache",
            key: 'harbours',
            render: (u) => (
                <>
                    {ArrayService.unique(
                        u.rolesInHarbours.map((r) => r.harbourId),
                    )
                        .map((id) => harbours.find((h) => h.id === id))
                        .filter((h) => !!h)
                        .map((h) => (
                            <div>{h.name}</div>
                        ))}
                    {ArrayService.unique(u.pendingAccessRequestHarbours)
                        .map((id) => harbours.find((h) => h.id === id))
                        .filter((h) => !!h)
                        .map((h) => (
                            <div className="--request-pending">{h.name}</div>
                        ))}
                </>
            ),
        },
        {
            title: 'Bateaux',
            key: 'boats',
            render: (u) =>
                u.boats.map((b) => (
                    <div>{`${b.name} (${
                        b.place ? b.place.code : 'Sans emplacement'
                    })`}</div>
                )),
        },
        {
            title: 'CGU Acceptées',
            key: 'endUserLicenseAgreementAccepted',
            render: (u) => (u.endUserLicenseAgreementAccepted ? 'Oui' : 'Non'),
            sorter: ArrayService.sortNumberColumn(
                'endUserLicenseAgreementAccepted',
            ),
        },
        {
            title: 'Appli mobile téléchargée',
            key: 'useMobileApp',
            render: (u) => (u.useMobileApp ? 'Oui' : 'Non'),
            sorter: ArrayService.sortNumberColumn('useMobileApp'),
        },
        {
            title: 'Créé le',
            key: 'createdAt',
            render: (u) => DateService.formatApiToDisplay(u.createdAt),
            sorter: ArrayService.sortStringColumn('createdAt'),
        },
    ];

    return (
        <Table
            dataSource={data}
            rowKey="id"
            columns={columns}
            locale={Locale.Table}
            loading={loading && { indicator: <LoadingIcon /> }}
        />
    );
}

function Filters({ filters, onChange, loading, onSubmit }) {
    const harbours = useHarbours();
    return (
        <div className="__filters">
            <InputFilter
                label="Prénom"
                value={filters.firstName}
                onChange={(v) => onChange({ ...filters, firstName: v })}
            />
            <InputFilter
                label="Nom"
                value={filters.lastName}
                onChange={(v) => onChange({ ...filters, lastName: v })}
            />
            <InputFilter
                label="Email"
                value={filters.email}
                onChange={(v) => onChange({ ...filters, email: v })}
            />
            <InputFilter
                label="Phone"
                value={filters.phone}
                onChange={(v) => onChange({ ...filters, phone: v })}
            />
            <SelectFilter
                label="Port d'attache"
                value={filters.harbour}
                onChange={(v) => onChange({ ...filters, harbour: v })}
                options={harbours.map((h) => ({ label: h.name, value: h.id }))}
            />
            <InputFilter
                label="Place"
                value={filters.place}
                onChange={(v) => onChange({ ...filters, place: v })}
            />
            <SelectFilter
                label="CGU acceptées"
                value={filters.eula}
                onChange={(v) => onChange({ ...filters, eula: v })}
                options={[
                    { label: 'Oui', value: true },
                    { label: 'Non', value: false },
                ]}
            />
            <SelectFilter
                label="App mobile"
                value={filters.mobile}
                onChange={(v) => onChange({ ...filters, mobile: v })}
                options={[
                    { label: 'Oui', value: true },
                    { label: 'Non', value: false },
                ]}
            />

            <Button onClick={onSubmit} loading={loading} type="primary">
                Lancer
            </Button>
        </div>
    );
}

function SelectFilter({ label, value, options, onChange }) {
    return (
        <div className="__filter">
            <label>
                {`${label} : `}
                <Select
                    value={value}
                    onChange={onChange}
                    options={options.sort((o1, o2) =>
                        StringService.compareCaseInsensitive(
                            o1.label,
                            o2.label,
                        ),
                    )}
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                        return (
                            option?.label
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        );
                    }}
                />
            </label>
        </div>
    );
}

function InputFilter({ label, value, onChange }) {
    return (
        <div className="__filter">
            <label>
                {`${label} : `}
                <Input
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                />
            </label>
        </div>
    );
}

function useUsers(filters) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const refetch = useCallback(() => {
        setLoading(true);
        ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}users?${Object.keys(filters)
                .filter((k) => filters[k] !== undefined)
                .reduce(
                    (res, curr, i) =>
                        `${res}${i > 0 ? '&' : ''}${curr}=${filters[curr]}`,
                    '',
                )}`,
        })
            .then(setData)
            .catch(() => {
                ToastActions.createToastError('Une erreur est survenue');
            })
            .finally(() => setLoading(false));
    }, [filters]);

    return { loading, data, refetch };
}
