import React from 'react';
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Locale from 'locale/LocaleFactory';

export default function LoginForm({ loading, fields, onChange, onSubmit }) {
    return (
        <Form
            layout="vertical"
            fields={fields}
            onFieldsChange={(_, allFields) => {
                onChange(allFields);
            }}
            onFinish={onSubmit}
        >
            <Form.Item
                name="username"
                rules={[
                    {
                        required: true,
                        message: Locale.trans('login.username.error.required'),
                    },
                ]}
            >
                <Input
                    addonBefore={<UserOutlined />}
                    placeholder={Locale.trans('login.username')}
                />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[
                    {
                        required: true,
                        message: Locale.trans('login.password.error.required'),
                    },
                ]}
            >
                <Input
                    addonBefore={<LockOutlined />}
                    type="password"
                    placeholder={Locale.trans('login.password')}
                />
            </Form.Item>
            <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
            >
                {Locale.trans('login.logIn.button')}
            </Button>
            <Link to="/reset-password-request" className="reset-password">
                J&apos;ai oublié mon mot de passe
            </Link>
        </Form>
    );
}
