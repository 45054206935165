import React from 'react';
import { Form, Button, DatePicker, Select } from 'antd';
import Locale from 'locale/LocaleFactory';

function cmpBoatByName(u1, u2) {
    const uN1 = u1.name;
    const uN2 = u2.name;
    return uN1 > uN2 ? 1 : -1;
}

export default function OutingForm({
    outing,
    boats,
    loading,
    fields,
    onChange,
    onSubmit,
}) {
    const [form] = Form.useForm();

    function handleSubmit(values) {
        const outing = getEntityFromValues(values);
        onSubmit(outing);
    }

    function getEntityFromValues(values) {
        const entity = {};
        const keys = Object.keys(values);
        const ignoredKeys = [];
        keys.forEach((k) => {
            if (k.endsWith('_ids')) {
                return;
            }
            if (k.endsWith('_id')) {
                const tK = k.replace('_id', '');
                entity[tK] = values[k];
                ignoredKeys.push(tK);
            } else if (ignoredKeys.indexOf(k) === -1) {
                entity[k] = values[k];
            }
        });
        return entity;
    }

    return (
        <Form
            form={form}
            layout="vertical"
            fields={fields}
            onFieldsChange={(_, allFields) => {
                onChange(allFields);
            }}
            onFinish={handleSubmit}
        >
            <BoatsField outing={outing} boats={boats} form={form} />
            <Form.Item
                name="datetimeOut"
                label="Départ"
                rules={[
                    {
                        required: true,
                        message: Locale.trans('error.required'),
                    },
                ]}
            >
                <DatePicker showTime />
            </Form.Item>
            <Form.Item
                name="datetimeIn"
                label="Retour"
                rules={[
                    {
                        required: true,
                        message: Locale.trans('error.required'),
                    },
                ]}
            >
                <DatePicker showTime />
            </Form.Item>

            <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
            >
                {Locale.trans('save')}
            </Button>
        </Form>
    );
}

function BoatsField({ outing, boats, form }) {
    if (!boats || boats.length === 0) {
        if (outing && outing.boat && outing.boat.name) {
            return (
                <div>
                    <label>Bateau : </label>
                    {outing.boat.name}
                </div>
            );
        }
        return null;
    }
    const { getFieldValue } = form;
    const options = boats.sort(cmpBoatByName).map((c) => ({
        value: c.id,
        label: `${c.name} (${c.immatriculation})`,
    }));
    return (
        <Form.Item
            name="boat_id"
            label="Bateau"
            initialValue={
                getFieldValue('boat')
                    ? getFieldValue('boat').id.toString()
                    : null
            }
        >
            <Select>
                {options.map((o) => (
                    <Select.Option key={o.value} value={o.value}>
                        {o.label}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
}
