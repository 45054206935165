import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import frFR from 'antd/es/locale/fr_FR';

import LoginActions from 'actions/LoginActions';

import Layout from 'pages/Layout.jsx';
import Login from 'pages/Login.jsx';
import ResetPassword from 'pages/ResetPassword.jsx';
import ResetPasswordRequest from 'pages/ResetPasswordRequest.jsx';

import ProtectedRoute from 'components/ProtectedRoute.jsx';

import 'antd/dist/antd.css';
import 'styles/style.scss';

LoginActions.loginUserIfRemembered();
export default function App() {
    return (
        <ConfigProvider locale={frFR}>
            <Router>
                <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route
                        exact
                        path="/reset-password"
                        component={ResetPassword}
                    />
                    <Route
                        exact
                        path="/reset-password-request"
                        component={ResetPasswordRequest}
                    />
                    <ProtectedRoute path="/" component={Layout} />
                </Switch>
            </Router>
        </ConfigProvider>
    );
}
