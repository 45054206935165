import React, { useState } from 'react';

import { Dropdown, Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import CountryUtil from './CountryUtil';
import FlagFactory from './FlagFactory';
import './CountrySelect.scss';
import PhoneUtil from './PhoneUtil';

interface Props {
    value: string;
    type?: 'dial' | 'name' | 'flag';
    onChange: (newvalue: string) => void;
}

export default function CountrySelect({ value, type, onChange }: Props) {
    const flag = FlagFactory.get(value);
    const country = PhoneUtil.getCountryDataByCode(value);

    return (
        <Dropdown
            overlay={
                <CountrySelectDropdown value={value} onChange={onChange} />
            }
            placement="bottomLeft"
            trigger={['click']}
        >
            <Button className="country-select">
                <div className="__content">
                    <div className="__flag">
                        {flag ? <img src={flag} alt={value} /> : <span>?</span>}
                    </div>
                    {(!type || type === 'name') && (
                        <span>{country && country.name}</span>
                    )}
                    {type === 'dial' && (
                        <span>{country && `+${country.dialCode}`}</span>
                    )}
                </div>
            </Button>
        </Dropdown>
    );
}

interface CountrySelectDropdownProps {
    value: string | undefined;
    onChange: (newValue: string) => void;
}
function CountrySelectDropdown({
    value,
    onChange,
}: CountrySelectDropdownProps) {
    const [search, setSearch] = useState('');
    const countries = CountryUtil.getAll();

    const lSearch = search.toLowerCase();
    return (
        <div className="country-select-dropdown">
            <div className="__search">
                <Input
                    prefix={<SearchOutlined />}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            <div className="__content">
                {countries
                    .filter(
                        (c) =>
                            search.length === 0 ||
                            c.name.toLowerCase().includes(lSearch),
                    )
                    .map((c) => {
                        const flag = FlagFactory.get(c.iso2);
                        const selected = value === c.iso2;
                        return (
                            <Button
                                onClick={() => onChange(c.iso2)}
                                className={`__option ${
                                    selected ? '--selected' : ''
                                }`}
                            >
                                {flag && <img src={flag} alt={c.iso2} />}
                                <span>{c.name}</span>
                            </Button>
                        );
                    })}
            </div>
        </div>
    );
}
