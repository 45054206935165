import React, { useState } from 'react';
import { Row, Col } from 'antd';

import LoginForm from 'components/forms/LoginForm.jsx';
import AuthService from 'services/AuthService';
import ToastActions from 'actions/ToastActions';

export default function Login({ history }) {
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState([]);

    function handleSubmit({ username, password }) {
        setLoading(true);
        AuthService.login(username, password)
            .then(() => {
                history.push('/');
            })
            .catch(handleError)
            .finally(() => {
                setLoading(false);
            });
    }

    function handleError(err) {
        const resp = JSON.parse(err.response);
        ToastActions.createToastError(resp.message);
    }

    return (
        <Row
            type="flex"
            align="middle"
            justify="center"
            className="login-page"
            style={{ backgroundImage: 'url("images/login-background.jpg")' }}
        >
            <Col style={{ minWidth: 280 }} xs={14} sm={8} md={6} lg={4} xl={4}>
                <img
                    src="images/logo.png"
                    alt="Logo Nauticspot"
                    style={{ width: '100%', paddingBottom: '1rem' }}
                />
                <LoginForm
                    onChange={setFields}
                    fields={fields}
                    onSubmit={handleSubmit}
                    loading={loading}
                />
            </Col>
        </Row>
    );
}
