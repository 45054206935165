import React from 'react';
import Panel from 'components/Panel';

import ExportActivityLogs from 'pages/ExportActivityLogs';

export default function Reporting() {
    return (
        <div>
            <Panel title="Reporting">
                <div>Bienvenue sur la page de reporting.</div>
            </Panel>
            <br />
            <ExportActivityLogs />
        </div>
    );
}
