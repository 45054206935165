import ServiceBase from 'services/ServiceBase';
import BaseUrlConstants from 'constants/BaseUrlConstants';
import TokenContainer from 'services/TokenContainer';
import DateService from 'services/utils/DateService';
import { Moment } from 'moment';

const URL: string = `${BaseUrlConstants.BASE_URL}`;

class ActivityLogService extends ServiceBase {
    constructor() {
        super(URL);
    }

    /**
     * Get summaries of activity for each boats of a harbour.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getSummariesByHarbour(harbourId: number): Promise<any> {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/activity-log-summaries`,
            method: 'GET',
        });
    }

    downloadActivityLogs(
        harbourId: number | string,
        startDate: Moment,
        endDate: Moment,
    ) {
        const url = `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/activity-logs.csv`;
        const token = TokenContainer.get();
        const formattedToken = token ? token.replace(/\+/g, '%2B') : '';
        const startDateParam = startDate
            ? `&start-date=${DateService.formatApi(startDate)}`
            : '';
        const endDateParam = endDate
            ? `&end-date=${DateService.formatApi(endDate)}`
            : '';
        window.open(
            `${url}?X-Auth-Token=${formattedToken}${startDateParam}${endDateParam}`,
        );
    }
}

export default new ActivityLogService();
