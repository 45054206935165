export default class FilterService {
    static matchFilter(
        filter: string | number | Array<number | string>,
        value: string | number | Array<number | string> | null,
    ) {
        if (!filter) {
            return true;
        }

        let match = false;
        if (Array.isArray(filter)) {
            if (filter.length === 0) {
                return true;
            }
            if (Array.isArray(value)) {
                match = filter.some((r) => value.indexOf(r) >= 0);
            } else if (value) {
                match = filter.length === 0 || filter.indexOf(value) !== -1;
            }
        } else if (value){
            match = filter.toString() === value.toString();
        }
        return match;
    }
}
