import React from 'react';
import moment, { Moment } from 'moment';

import { Table, Button, Row, Col, DatePicker, Select } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import TableColumnFilter from 'components/TableColumnFilter';
import FilterIcon from 'components/FilterIcon';
import CreateOutingModal from 'pages/CreateOutingModal';
import EditOutingModal from 'pages/EditOutingModal';
import LoadingIcon from 'components/LoadingIcon';
import DeleteButton from 'components/forms/DeleteButton';

import Locale from 'locale/LocaleFactory';
import ArrayService from 'services/utils/ArrayService';
import StringService from 'services/utils/StringService';
import FilterService from 'services/utils/FilterService';

import OutingActions from 'actions/OutingActions';
import OutingStore from 'stores/OutingStore';

import HarbourActions from 'actions/HarbourActions';
import HarbourStore from 'stores/HarbourStore';

// Sorting Methods
function sortHarbourColumn(o1: IOuting, o2: IOuting) {
    return StringService.compareCaseInsensitive(
        o1.harbour.name,
        o2.harbour.name,
    );
}
function sortBoatColumn(o1: IOuting, o2: IOuting) {
    return StringService.compareCaseInsensitive(o1.boat ? o1.boat.name : "", o2.boat ? o2.boat.name : "");
}
function sortDatetimeOutColumn(o1: IOuting, o2: IOuting) {
    return StringService.compareCaseInsensitive(o1.datetimeOut, o2.datetimeOut);
}
function sortDatetimeInColumn(o1: IOuting, o2: IOuting) {
    return StringService.compareCaseInsensitive(o1.datetimeIn, o2.datetimeIn);
}

type Props = {};
type State = {
    loading: boolean;
    createOutingVisible: boolean;
    editOutingVisible: boolean;
    outingToEdit: IOuting | null;

    outings: IOuting[];
    filteredOutings: IOuting[];
    harbours: IHarbour[];
    selectedHarbourId: number | null;
    startDate: Moment;
    endDate: Moment;

    // Filters
    filterGlobal: string;
    filters: {
        harbour: any[];
        boat: any[];
        place: any[];
    };
};

/**
 * The list of the outings.
 */
export default class OutingList extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loading: false,
            outings: [],

            filteredOutings: [],

            filterGlobal: '',
            filters: {
                harbour: [],
                boat: [],
                place: [],
            },

            createOutingVisible: false,
            editOutingVisible: false,
            outingToEdit: null,

            harbours: HarbourStore.getAll(),
            selectedHarbourId: null,
            startDate: moment().add(-1, 'days'),
            endDate: moment(),
        };
    }

    componentDidMount() {
        OutingStore.addChangeListener(this.receiveOutings);

        HarbourStore.addChangeListener(this.receiveHarbours);
        HarbourActions.reload();

        this.updateFilters();
    }
    componentWillUnmount() {
        OutingStore.removeChangeListener(this.receiveOutings);
        HarbourStore.removeChangeListener(this.receiveHarbours);
    }

    receiveHarbours = () => {
        this.setState({
            harbours: HarbourStore.getAll(),
        });
    };

    handleHarbourChange = (harbourId: string) => {
        this.setState({
            selectedHarbourId: parseInt(harbourId, 10),
        });
    };

    handleStartDateChange = (startDate: Moment | null) => {
        startDate && this.setState({ startDate });
    };

    handleEndDateChange = (endDate: Moment | null) => {
        endDate && this.setState({ endDate });
    };

    reloadData = () => {
        this.loadOutings();
    };

    loadOutings = () => {
        const { selectedHarbourId, startDate, endDate } = this.state;
        const harbour = selectedHarbourId
            ? HarbourStore.getById(selectedHarbourId)
            : null;

        this.setState({
            loading: true,
        });
        harbour &&
            OutingActions.reloadByHarbour(harbour, startDate, endDate).then(
                () => {
                    this.setState({
                        loading: false,
                    });
                },
            );
    };

    receiveOutings = () => {
        const { selectedHarbourId, startDate, endDate } = this.state;
        const harbour = selectedHarbourId
            ? HarbourStore.getById(selectedHarbourId)
            : null;

        const outings = harbour
            ? OutingStore.getByHarbour(harbour, startDate, endDate)
            : [];
        this.setState(
            {
                outings,
            },
            this.updateFilters,
        );
    };

    // Filters
    updateFilters = () => {
        const { outings } = this.state;
        this.setState({
            filteredOutings: outings.filter(this.outingMatchFilters),
        });
    };

    outingMatchFilters = (o: IOuting) => {
        const { filters } = this.state;
        return (
            FilterService.matchFilter(filters.harbour, o.harbour ? o.harbour.id : null) &&
            FilterService.matchFilter(filters.boat, o.boat ? o.boat.id : null) &&
            FilterService.matchFilter(filters.place, o.place ? o.place.id : null)
        );
    };

    handleFilterChange = (name: string, values: Array<string | number>) => {
        this.setState(
            (prev) => ({
                filters: { ...prev.filters, [name]: values },
            }),
            this.updateFilters,
        );
    };

    getHarbours = () =>
        ArrayService.uniqueEntity(this.state.outings.map((o) => o.harbour));

    getBoats = () =>
        ArrayService.uniqueEntity(this.state.outings.map((o) => o.boat));

    getPlaces = () =>
        ArrayService.uniqueEntity(this.state.outings.map((o) => o.place));

    showCreateOutingModal = () => {
        this.setState({
            createOutingVisible: true,
        });
    };
    hideCreateOutingModal = () => {
        this.setState({
            createOutingVisible: false,
        });
    };

    editOuting = (outing: IOuting) => {
        this.setState({
            editOutingVisible: true,
            outingToEdit: outing,
        });
    };
    hideEditOutingModal = () => {
        this.setState({
            editOutingVisible: false,
            outingToEdit: null,
        });
    };

    deleteOuting = (outing: IOuting) => {
        const { selectedHarbourId } = this.state;
        const harbour = selectedHarbourId
            ? HarbourStore.getById(selectedHarbourId)
            : null;
        harbour && OutingActions.delete(harbour, outing.id);
    };

    render() {
        const {
            selectedHarbourId,
            outingToEdit,
            loading,
            createOutingVisible,
            editOutingVisible,
        } = this.state;
        const harbour = selectedHarbourId
            ? HarbourStore.getById(selectedHarbourId)
            : null;
        return (
            <div className="outing-list">
                {this.renderGlobalFilters()}
                {this.renderOutingTable()}

                {!loading && (
                    <div className="actions-row">
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={this.showCreateOutingModal}
                        >
                            Ajouter une sortie
                        </Button>
                    </div>
                )}

                {createOutingVisible && harbour && (
                    <CreateOutingModal
                        harbour={harbour}
                        onCancel={this.hideCreateOutingModal}
                        visible={this.state.createOutingVisible}
                    />
                )}
                {editOutingVisible && (
                    <EditOutingModal
                        harbour={harbour}
                        outing={outingToEdit}
                        onCancel={this.hideEditOutingModal}
                        visible={editOutingVisible}
                    />
                )}
            </div>
        );
    }

    renderOutingTable() {
        const { filters, filteredOutings } = this.state;

        const columns = [
            {
                title: 'Port',
                key: 'harbour',
                render: this.renderHarbourCell,
                sorter: sortHarbourColumn,
                filterIcon: <FilterIcon active={filters.harbour.length > 0} />,
                filterDropdown: (
                    <TableColumnFilter
                        name="harbour"
                        selectedValues={filters.harbour}
                        values={this.getHarbours().map((h: any) => ({
                            text: h.name,
                            value: h.id,
                        }))}
                        onChange={this.handleFilterChange}
                    />
                ),
            },
            {
                title: 'Bateau',
                key: 'boat',
                render: this.renderBoatCell,
                sorter: sortBoatColumn,
                filterIcon: <FilterIcon active={filters.boat.length > 0} />,
                filterDropdown: (
                    <TableColumnFilter
                        name="boat"
                        selectedValues={filters.boat}
                        values={this.getBoats().map((b: any) => ({
                            text: b ? b.name : "",
                            value: b.id,
                        }))}
                        onChange={this.handleFilterChange}
                    />
                ),
            },
            {
                title: 'Départ',
                key: 'datetimeOut',
                render: this.renderDatetimeOutCell,
                sorter: sortDatetimeOutColumn,
                defaultSortOrder: 'descend' as 'descend',
            },
            {
                title: 'Retour',
                key: 'datetimeIn',
                render: this.renderDatetimeInCell,
                sorter: sortDatetimeInColumn,
            },
            {
                title: null,
                key: 'actions',
                width: '50px',
                render: this.rendActionsCell,
            },
        ];

        return (
            <Table
                dataSource={filteredOutings}
                rowKey="id"
                columns={columns}
                locale={Locale.Table}
                loading={this.state.loading && { indicator: <LoadingIcon /> }}
            />
        );
    }

    renderDatetimeOutCell = (outing: IOuting) => {
        if (!outing.datetimeOut) {
            return null;
        }
        const date = moment(outing.datetimeOut, 'YYYY-MM-DD HH:mm');
        return date.format('DD/MM/YYYY HH:mm');
    };

    renderDatetimeInCell = (outing: IOuting) => {
        if (!outing.datetimeIn) {
            return null;
        }
        const date = moment(outing.datetimeIn, 'YYYY-MM-DD HH:mm');
        return date.format('DD/MM/YYYY HH:mm');
    };

    renderHarbourCell = (outing: IOuting) => outing.harbour.name;

    renderBoatCell = (outing: IOuting) => outing.boat ? outing.boat.name : "";

    rendActionsCell = (outing: IOuting) => (
        <React.Fragment>
            <div style={{ display: 'flex' }}>
                <Button
                    type="primary"
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={(e) => {
                        this.editOuting(outing);
                        e.stopPropagation();
                        e.preventDefault();
                        return false;
                    }}
                />
                <DeleteButton
                    onDelete={() => {
                        this.deleteOuting(outing);
                    }}
                    shape="circle"
                />
            </div>
        </React.Fragment>
    );

    renderGlobalFilters() {
        const { harbours, selectedHarbourId, startDate, endDate } = this.state;
        if (!harbours) {
            return null;
        }

        return (
            <Row gutter={16} style={{ marginBottom: 16 }}>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <label>
                        Port :
                        <Select
                            style={{ width: '100%' }}
                            value={
                                selectedHarbourId
                                    ? selectedHarbourId.toString()
                                    : undefined
                            }
                            onChange={this.handleHarbourChange}
                            showSearch
                            filterOption={(input, option) =>
                                option?.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {harbours
                                .sort((h1, h2) =>
                                    StringService.compareCaseInsensitive(
                                        h1.name,
                                        h2.name,
                                    ),
                                )
                                .map((h) => (
                                    <Select.Option
                                        key={h.id}
                                        value={h.id.toString()}
                                    >
                                        {h.name}
                                    </Select.Option>
                                ))}
                        </Select>
                    </label>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <label>
                        Début :
                        <DatePicker
                            value={startDate}
                            onChange={this.handleStartDateChange}
                        />
                    </label>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <label>
                        Fin :
                        <DatePicker
                            value={endDate}
                            onChange={this.handleEndDateChange}
                        />
                    </label>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <br />
                    <Button type="primary" onClick={this.reloadData}>
                        Lancer
                    </Button>
                </Col>
            </Row>
        );
    }
}
