import React from 'react';
import { Form, Input, Button } from 'antd';
import Locale from 'locale/LocaleFactory';

export default function ResetPasswordRequestForm({
    loading,
    fields,
    onChange,
    onSubmit,
}) {
    return (
        <Form
            layout="vertical"
            fields={fields}
            onFieldsChange={(_, allFields) => {
                onChange(allFields);
            }}
            onFinish={onSubmit}
        >
            <Form.Item
                name="email"
                rules={[
                    {
                        required: true,
                        message: Locale.trans('user.email.error.required'),
                    },
                ]}
            >
                <Input placeholder={Locale.trans('user.email')} />
            </Form.Item>
            <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
            >
                {Locale.trans('send')}
            </Button>
        </Form>
    );
}
