import React, { useState, useEffect, useMemo } from 'react';

import { Table, Button, Tag } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import TableColumnFilter from 'components/TableColumnFilter';
import FilterIcon from 'components/FilterIcon';
import CreateHarbourModal from 'pages/CreateHarbourModal';
import EditHarbourModal from 'pages/EditHarbourModal';
import LoadingIcon from 'components/LoadingIcon';

import Locale from 'locale/LocaleFactory';
import ArrayService from 'services/utils/ArrayService';
import StringService from 'services/utils/StringService';
import FilterService from 'services/utils/FilterService';

import HarbourActions from 'actions/HarbourActions';
import HarbourStore from 'stores/HarbourStore';

// Sorting Methods
function sortNameColumn(h1: IHarbour, h2: IHarbour) {
    return StringService.compareCaseInsensitive(h1.name, h2.name);
}

const MODULES = [
    'weather',
    'webcam',
    'info',
    'absence',
    'security',
    'commitment',
    'rate',
    'communication',
];

function cmpModuleOptions(v1: any, v2: any) {
    return StringService.compareCaseInsensitive(
        Locale.trans(`harbour.modules.levels.${v1.value}`, {}),
        Locale.trans(`harbour.modules.levels.${v2.value}`, {}),
    );
}

/**
 * The list of the harbours.
 */
export default function HarbourList() {
    const [harbours, setHarbours] = useState(HarbourStore.getAll());
    const [loading, setLoading] = useState(!harbours.length);
    const [filters, setFilters] = useState({ name: [] } as {
        [name: string]: (string | number)[];
    });
    const [createHarbourVisible, setCreateHarbourVisible] = useState(false);
    const [harbourToEdit, setHarbourToEdit] = useState(null as IHarbour | null);

    useEffect(() => {
        function receiveHarbours() {
            setHarbours(HarbourStore.getAll());
        }
        HarbourStore.addChangeListener(receiveHarbours);

        setLoading(true);
        HarbourActions.reload().finally(() => {
            setLoading(false);
        });

        return () => {
            HarbourStore.removeChangeListener(receiveHarbours);
        };
    }, []);
    const filteredHarbours = useMemo(() => {
        function harbourMatchFilters(h: any) {
            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                let match;
                if (key === 'isPartner' || key === 'isPublic') {
                    match = FilterService.matchFilter(
                        filters[key],
                        h[key] ? 'true' : 'false',
                    );
                } else if (h[key]) {
                    match = FilterService.matchFilter(filters[key], h[key]);
                } else if (key.includes('.')) {
                    const sKey = key.split('.');
                    const value = sKey.reduce((value, k) => {
                        return value[k] !== undefined ? value[k] : value;
                    }, h);
                    match = FilterService.matchFilter(filters[key], value);
                } else {
                    match = true;
                }

                if (!match) {
                    return false;
                }
            }
            return true;
        }
        return harbours.filter(harbourMatchFilters);
    }, [harbours, filters]);

    function handleFilterChange(name: string, values: (string | number)[]) {
        setFilters((prev) => ({ ...prev, [name]: values }));
    }

    function getHarbourNames(): string[] {
        return ArrayService.unique(harbours.map((b) => b.name));
    }

    function showCreateHarbourModal() {
        setCreateHarbourVisible(true);
    }
    function hideCreateHarbourModal() {
        setCreateHarbourVisible(false);
    }

    function editHarbour(harbour: IHarbour) {
        setHarbourToEdit(harbour);
    }
    function hideEditHarbourModal() {
        setHarbourToEdit(null);
    }

    const columns = [
        {
            title: Locale.trans('harbour.name', {}),
            dataIndex: 'name',
            key: 'name',
            sorter: sortNameColumn,
            filterIcon: <FilterIcon active={filters.name.length > 0} />,
            filterDropdown: (
                <TableColumnFilter
                    name="name"
                    selectedValues={filters.name}
                    values={getHarbourNames()
                        .map((r) => ({
                            text: r,
                            value: r,
                        }))
                        .sort((v1, v2) =>
                            StringService.compareCaseInsensitive(
                                v1.text,
                                v2.text,
                            ),
                        )}
                    onChange={handleFilterChange}
                />
            ),
            defaultSortOrder: 'ascend' as 'ascend',
        },
        {
            title: Locale.trans('harbour.isPartner', {}),
            key: 'isPartner',
            render: (h: any) => (h.isPartner ? 'Oui' : 'Non'),
            filterIcon: (
                <FilterIcon
                    active={filters.isPartner && filters.isPartner.length > 0}
                />
            ),
            filterDropdown: (
                <TableColumnFilter
                    name="isPartner"
                    selectedValues={filters.isPartner}
                    values={[
                        {
                            text: 'Oui',
                            value: 'true',
                        },
                        { text: 'Non', value: 'false' },
                    ]}
                    onChange={handleFilterChange}
                />
            ),
            defaultSortOrder: 'ascend' as 'ascend',
        },
        {
            title: Locale.trans('harbour.isPublic', {}),
            key: 'isPublic',
            render: (h: any) => (h.isPublic ? 'Oui' : 'Non'),
            filterIcon: (
                <FilterIcon
                    active={filters.isPublic && filters.isPublic.length > 0}
                />
            ),
            filterDropdown: (
                <TableColumnFilter
                    name="isPublic"
                    selectedValues={filters.isPublic}
                    values={[
                        {
                            text: 'Oui',
                            value: 'true',
                        },
                        { text: 'Non', value: 'false' },
                    ]}
                    onChange={handleFilterChange}
                />
            ),
            defaultSortOrder: 'ascend' as 'ascend',
        },
        ...MODULES.map((module) => ({
            key: module,
            title: Locale.trans(`harbour.modules.${module}`, {}),
            filterIcon: (
                <FilterIcon
                    active={(filters[`modules.${module}`] || []).length > 0}
                />
            ),
            filterDropdown: (
                <TableColumnFilter
                    name={`modules.${module}`}
                    selectedValues={filters[`modules.${module}`] || []}
                    values={[0, 1, 2].map((level) => ({
                        value: level,
                        text: (
                            <Tag color={['blue', 'green', 'gold'][level]}>
                                {Locale.trans(
                                    `harbour.modules.levels.${level}`,
                                    {},
                                )}
                            </Tag>
                        ),
                    }))}
                    sort={cmpModuleOptions}
                    onChange={handleFilterChange}
                />
            ),
            render: (harbour: IHarbour) => {
                const level = (harbour.modules && harbour.modules[module]) || 0;
                return (
                    <Tag color={['blue', 'green', 'gold'][level]}>
                        {Locale.trans(`harbour.modules.levels.${level}`, {})}
                    </Tag>
                );
            },
        })),
        {
            title: null,
            key: 'actions',
            width: '50px',
            render: (harbour: IHarbour) => (
                <Button
                    type="primary"
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={(e) => {
                        editHarbour(harbour);
                        e.stopPropagation();
                        e.preventDefault();
                        return false;
                    }}
                />
            ),
        },
    ];

    return (
        <div className="harbour-list">
            <Table
                dataSource={filteredHarbours}
                rowKey="id"
                columns={columns}
                locale={Locale.Table}
                loading={loading && { indicator: <LoadingIcon /> }}
            />

            {!loading && (
                <div className="actions-row" style={{ marginTop: '-50px' }}>
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={showCreateHarbourModal}
                    >
                        Ajouter un port
                    </Button>
                </div>
            )}

            <CreateHarbourModal
                onCancel={hideCreateHarbourModal}
                visible={createHarbourVisible}
            />
            <EditHarbourModal
                harbour={harbourToEdit}
                onCancel={hideEditHarbourModal}
                visible={!!harbourToEdit}
            />
        </div>
    );
}
