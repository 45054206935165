import React from 'react';

import { Button, Row, Col, Select, DatePicker } from 'antd';

import Panel from 'components/Panel';
import ActivityLogService from 'services/ActivityLogService';

import HarbourActions from 'actions/HarbourActions';
import HarbourStore from 'stores/HarbourStore';

export default class ExportActivityLogs extends React.Component {
    constructor() {
        super();
        this.state = {
            harbours: HarbourStore.getAll(),
            selectedHarbourId: null,
            startDate: null,
            endDate: null,
        };
    }

    componentDidMount() {
        HarbourStore.addChangeListener(this.receiveHarbours);
        HarbourActions.reload();
    }
    componentWillUnmount() {
        HarbourStore.removeChangeListener(this.receiveHarbours);
    }

    receiveHarbours = () => {
        this.setState({
            harbours: HarbourStore.getAll(),
        });
    };

    handleHarbourChange = (harbourId) => {
        this.setState({
            selectedHarbourId: harbourId,
        });
    };

    handleStartDateChange = (startDate) => {
        this.setState({ startDate });
    };

    handleEndDateChange = (endDate) => {
        this.setState({ endDate });
    };

    exportLogs = () => {
        const { selectedHarbourId, startDate, endDate } = this.state;
        if (!selectedHarbourId || !startDate || !endDate) {
            return;
        }
        ActivityLogService.downloadActivityLogs(
            selectedHarbourId,
            startDate,
            endDate,
        );
    };

    render() {
        const { harbours, selectedHarbourId, startDate, endDate } = this.state;
        if (!harbours) {
            return null;
        }

        return (
            <Panel title="Exporter les sorties">
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                        <label>
                            Port :
                            <Select
                                style={{ width: '100%' }}
                                value={selectedHarbourId}
                                onChange={this.handleHarbourChange}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option?.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {harbours.map((h) => (
                                    <Select.Option
                                        key={h.id}
                                        value={h.id.toString()}
                                    >
                                        {h.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </label>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                        <label>
                            Début :
                            <DatePicker
                                value={startDate}
                                onChange={this.handleStartDateChange}
                            />
                        </label>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                        <label>
                            Fin :
                            <DatePicker
                                value={endDate}
                                onChange={this.handleEndDateChange}
                            />
                        </label>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                        <br />
                        <Button type="primary" onClick={this.exportLogs}>
                            Exporter
                        </Button>
                    </Col>
                </Row>
            </Panel>
        );
    }
}
