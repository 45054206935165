import dispatcher from 'dispatchers/AppDispatcher';
import ActionsBase from 'actions/ActionsBase';

import HarbourConstants from 'constants/HarbourConstants';
import HarbourService from 'services/HarbourService';

import CacheManager from '../services/CacheManager';

const CACHE_DURATION = 30 * 1000;

class HarbourActions extends ActionsBase {
    create = (harbour: IHarbour): Promise<IHarbour> => {
        const $this = this;
        return new Promise(
            (
                resolve: (harbour: IHarbour) => void,
                reject: (response: Object) => void,
            ): void => {
                function handleSuccess(newHarbour: IHarbour): void {
                    dispatcher.dispatch({
                        type: HarbourConstants.RECEIVE_HARBOUR,
                        payload: {
                            harbour: newHarbour,
                        },
                    });
                    resolve(newHarbour);
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                HarbourService.post(harbour)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };
    edit = (id: number, harbour: IHarbour): Promise<IHarbour> => {
        const $this = this;
        return new Promise(
            (
                resolve: (harbour: IHarbour) => void,
                reject: (response: Object) => void,
            ): void => {
                function handleSuccess(newHarbour: IHarbour): void {
                    dispatcher.dispatch({
                        type: HarbourConstants.RECEIVE_HARBOUR,
                        payload: {
                            harbour: newHarbour,
                        },
                    });
                    resolve(newHarbour);
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                HarbourService.patch(id, harbour)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };
    delete = (id: number): Promise<void> => {
        const $this = this;
        return new Promise(
            (resolve: () => void, reject: (response: Object) => void): void => {
                function handleSuccess(): void {
                    dispatcher.dispatch({
                        type: HarbourConstants.DELETE_HARBOUR,
                        payload: {
                            id,
                        },
                    });
                    resolve();
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                HarbourService.remove(id)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };
    reload = (force?: boolean): Promise<void> => {
        const $this = this;
        return new Promise(
            (resolve: () => void, reject: (response: Object) => void): void => {
                if (
                    CacheManager.isCached('Harbour:reload', '', CACHE_DURATION)
                    && !force
                ) {
                    resolve();
                    return;
                }

                function handleSuccess(harbours: Array<IHarbour>): void {
                    dispatcher.dispatch({
                        type: HarbourConstants.RECEIVE_HARBOURS,
                        payload: {
                            harbours,
                        },
                    });
                    resolve();
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                HarbourService.getAll().then(handleSuccess).catch(handleError);
            },
        );
    };

    reloadById = (id: number): Promise<void> => {
        const $this = this;
        return new Promise(
            (resolve: () => void, reject: (response: Object) => void): void => {
                function handleSuccess(harbour: IHarbour): void {
                    dispatcher.dispatch({
                        type: HarbourConstants.RECEIVE_HARBOUR,
                        payload: {
                            harbour,
                        },
                    });
                    resolve();
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                HarbourService.getById(id)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };
}

export default new HarbourActions();
