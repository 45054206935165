export default {
    // NauticSpot
    Trans: {
        beginning: 'Début',
        cost: 'Coût',
        date: 'Date',
        day: 'jour',
        week: 'semaine',
        month: 'mois',
        days: 'jours',
        description: 'Description',
        delete: 'Supprimer',
        'delete.confirm': 'Êtes vous sur de supprimer?',
        download: 'Télécharger',
        end: 'Fin',
        edit: 'Modifier',
        save: 'Enregistrer',
        send: 'Envoyer',
        cancel: 'Annuler',
        total: 'Total',
        notFound: 'Aucun',
        search: 'Rechercher',

        mister: 'Monsieur',
        miss: 'Madame',

        locale: {
            fr: 'Français',
            en: 'English',
            es: 'Español',
            it: 'Italiano',
        },

        facebook: 'Facebook',
        linkedin: 'Linkedin',
        twitter: 'Twitter',

        matin: 'Matin',
        apresmidi: 'Après-midi',
        refused: 'Refusée',
        approved: 'Acceptée',
        requested: 'En attente',
        accepted: 'Acceptée',

        monday: 'Lundi',
        tuesday: 'Mardi',
        wednesday: 'Mercredi',
        thursday: 'Jeudi',
        friday: 'Vendredi',
        saturday: 'Samedi',
        sunday: 'Dimanche',

        welcome: 'Bonjour __USER__',

        license: 'Oliviers tout droits réservés © 2018 Créé par IAS Ingenierie',

        address: 'Adresse',
        'address.street': 'Rue',
        'address.country': 'Pays',
        'address.city': 'Ville',
        'address.postalCode': 'Code Postal',

        error: {
            required: 'Merci de renseigner ce champ',
            'email.invalid': 'E-Mail invalide',
            'phone.invalid': 'Numéro de téléphone invalide',
            'amountquote.invalid': 'Montant de commande invalide',
            'socialSecurityNumber.invalid':
                'Numéro de sécurité social invalide',
        },

        advancedSearch: {
            show: 'Filtres Avancés',
            hide: 'Masquer les Filtres',
        },

        login: {
            username: "Nom d'Utilisateur",
            password: 'Mot de Passe',
            rememberMe: 'Se souvenir de moi',

            'logIn.button': 'Connexion',
            'logOut.button': 'Déconnexion',
            'username.error.required': 'Veuillez reseigner votre identifiant',
            'password.error.required': 'Veuillez taper votre mot de passe',
        },
        accessRequest: {
            categories: {
                yachtsman: 'Plaisancier',
                professional: 'Professionel',
                visitor: 'Visiteur',
            },
        },
        user: {
            firstName: 'Prénom',
            lastName: 'Nom',
            name: 'Nom',
            username: 'Identifiant',
            nationality: 'Nationalité',
            email: 'eMail',
            boats: 'Bateaux',
            phoneNumber: 'N° Téléphone',
            password: 'Mot de passe',
            passwordConfirm: 'Confirmez votre mot de passe',
            'password.error.required': 'Veuillez taper votre mot de passe',
        },
        absence: {
            absence: 'Absence',
            departureDate: 'Date de sortie',
            returnDate: 'Date de retour',
            anticipatedReturnDate: 'Date de retour anticipé',
            place: 'Place',
            boat: 'Bateau',
            owners: 'Plaisancier',
            legend: {
                pending: 'En Attente',
                approved: 'Disponible',
                present: 'Occupé',
                'anticipated-return': 'Retour Anticipé',
            },
        },
        multihull: 'Multi Coque',
        sailboat: 'Voilier',
        motorboat: 'Moteur',
        resident: 'Résident',
        escale: 'Escale',
        boat: {
            name: 'Nom',
            boatType: 'Type',
            place: 'Place',
            draught: "Tirant d'eau",
            length: 'Longueur',
            width: 'Largeur',
            size: 'Taille',
            immatriculation: 'Immatriculation',
            hasTank: 'Réservoir à eaux noires',
            owners: 'Propriétaires',
            position: 'Position',
            countAbsences: "Nombre d'absences",
            countOutings: 'Nombre de sorties',
        },
        place: {
            code: 'Code',
            boatTypes: 'Bateaux autorisés',
            placeType: 'Type de place',
            boat: 'Bateau',
            length: 'Longueur',
            width: 'Largeur',
            draught: "Tirant d'eau",
        },
        position: {
            in: 'Au Port',
            out: 'En Mer',
        },
        rate: {
            category: 'Catégorie',
            name: 'Code',
            boatTypes: 'Types de bateau',
            minLength: 'Longueur Min',
            maxLength: 'Longueur Max',
            minWidth: 'Largeur Min',
            maxWidth: 'Largeur Max',
        },
        harbour: {
            name: 'Nom',
            isPartner: 'Port partenaire',
            isPublic: 'Port public',
            defaultLocale: 'Langue',
            country: 'Pays',
            latitude: 'Latitude',
            longitude: 'Longitude',
            address: 'Adresse',
            phone: 'Téléphone',
            fax: 'Fax',
            email: 'Email',
            absenceEmail: "Email utilisé pour notifier une demande d'absence",
            modules: {
                weather: 'Météo',
                webcam: 'Caméras',
                info: 'Infos pratiques',
                absence: 'Absences',
                security: 'Sécurité',
                rate: 'Grille tarifaire',
                communication: 'Communication',
                commitment: 'Engagement',

                levels: {
                    0: 'Standard',
                    1: 'Avancé',
                    2: 'Premium',
                },
            },
        },
    },

    // Ant Design
    Table: {
        filterTitle: 'Rechercher...',
        filterConfirm: 'OK',
        filterReset: 'Réinitialiser',
        emptyText: 'Aucune donnée',
    },
    Modal: {
        okText: 'OK',
        cancelText: 'Annuler',
        justOkText: 'OK',
    },
    Popconfirm: {
        okText: 'OK',
        cancelText: 'Annuler',
    },
    Transfer: {
        notFoundContent: 'Pas de résultat',
        searchPlaceholder: 'Recherche',
        itemUnit: 'élément',
        itemsUnit: 'éléments',
    },
    Select: {
        notFoundContent: 'Pas de résultat',
    },
    Pagination: {
        // Options.jsx
        items_per_page: '/ page',
        jump_to: 'Allez à',
        page: '',

        // Pagination.jsx
        prev_page: 'Page Précédente',
        next_page: 'Page Suivante',
        prev_5: '5 Pages Précédentes',
        next_5: '5 Pages Suivantes',
        prev_3: '3 Pages Précédentes',
        next_3: '3 Pages Suivantes',
    },
    TimePicker: {
        placeholder: '',
    },
    DatePicker: {
        lang: {
            today: "Aujourd'hui",
            now: 'Maintenant',
            backToToday: "Retour à aujourdh'hui",
            ok: 'Ok',
            clear: 'Vider',
            month: 'Mois',
            year: 'Année',
            timeSelect: "Sélectionner l'heure",
            dateSelect: 'Sélectionner une date',
            monthSelect: 'Sélectionner un mois',
            yearSelect: 'Sélectionner une année',
            decadeSelect: 'Sélectionner une décénie',
            yearFormat: 'YYYY',
            dateFormat: 'M/D/YYYY',
            dayFormat: 'D',
            dateTimeFormat: 'M/D/YYYY HH:mm:ss',
            monthFormat: 'MMMM',
            monthBeforeYear: true,
            previousMonth: 'Mois précédent (PageUp)',
            nextMonth: 'Mois suivant (PageDown)',
            previousYear: 'Année précédente (Control + left)',
            nextYear: 'Année suivante (Control + right)',
            previousDecade: 'Décennie précédente',
            nextDecade: 'Décennie suivante',
            previousCentury: 'Siècle précédent',
            nextCentury: 'Siècle suivant',

            placeholder: 'Sélectionnez une date',
            rangePlaceholder: ['Date de début', 'Date de fin'],
        },
    },
};
