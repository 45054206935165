import React, { useState, useEffect, useCallback } from 'react';
import ServiceBase from 'services/ServiceBase';
import BaseUrlConstants from 'constants/BaseUrlConstants';
import { Radio, Button, Upload, Popconfirm } from 'antd';
import {
    PlusOutlined,
    LeftOutlined,
    RightOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import TokenContainer from 'services/TokenContainer';
import DeleteButton from 'components/forms/DeleteButton';
import Locale from 'locale/LocaleFactory';

import './Tutorial.scss';
import ToastActions from 'actions/ToastActions';

export default function Tutorials() {
    const [locale, setLocale] = useState('fr');
    const { data: tutorialTypes, loading } = useTutorialTypes();

    const options = [
        { label: 'Français', value: 'fr' },
        { label: 'Anglais', value: 'en' },
        { label: 'Espagnols', value: 'es' },
        { label: 'Italien', value: 'it' },
    ];
    return (
        <div className="tutorials">
            <div>
                <Radio.Group
                    onChange={(e) => setLocale(e.target.value)}
                    value={locale}
                    buttonStyle="solid"
                >
                    {options.map((o) => (
                        <Radio.Button key={o.value} value={o.value}>
                            {o.label}
                        </Radio.Button>
                    ))}
                </Radio.Group>
            </div>
            {loading && <div className="__loading">Chargement...</div>}
            <div>
                {tutorialTypes &&
                    tutorialTypes.map((type) => (
                        <Tutorial
                            key={type.value}
                            type={type}
                            locale={locale}
                        />
                    ))}
            </div>
        </div>
    );
}

function patchSlide(slideId, values) {
    return ServiceBase.execute({
        url: `${BaseUrlConstants.BASE_URL}tutorial-slides/${slideId}`,
        method: 'PATCH',
        data: values,
    });
}

function deleteSlide(slideId) {
    return ServiceBase.execute({
        url: `${BaseUrlConstants.BASE_URL}tutorial-slides/${slideId}`,
        method: 'DELETE',
    });
}

function Tutorial({ type, locale }) {
    const { data: slides, loading, refetch } = useTutorialSlides(
        locale,
        type.value,
    );

    function handlePrevMove(slide) {
        const prevSlide = slides.find(
            (s) => s.sortOrder === slide.sortOrder - 1,
        );
        if (prevSlide) {
            Promise.all([
                patchSlide(slide.id, {
                    sortOrder: slide.sortOrder - 1,
                }),
                patchSlide(prevSlide.id, {
                    sortOrder: slide.sortOrder,
                }),
            ]).then(refetch);
        }
    }
    function handleNextMove(slide) {
        const nextSlide = slides.find(
            (s) => s.sortOrder === slide.sortOrder + 1,
        );
        if (nextSlide) {
            Promise.all([
                patchSlide(slide.id, {
                    sortOrder: slide.sortOrder + 1,
                }),
                patchSlide(nextSlide.id, {
                    sortOrder: slide.sortOrder,
                }),
            ]).then(refetch);
        }
    }
    function handleDelete(slide) {
        deleteSlide(slide.id).then(refetch);
    }
    function handleChange(slide, values) {
        patchSlide(slide.id, values).then(refetch);
    }

    return (
        <div className="__tutorial">
            <div className="__header">
                <h2 className="__title">{type.label}</h2>
                <ResetButton type={type.value} />
                {loading && <div>Chargement...</div>}
            </div>
            <div className="__slides">
                {slides &&
                    slides
                        .sort((s1, s2) =>
                            s1.sortOrder > s2.sortOrder ? 1 : -1,
                        )
                        .map((s, i) => (
                            <Slide
                                key={s.id}
                                slide={s}
                                onMovePrev={() => handlePrevMove(s)}
                                onMoveNext={() => handleNextMove(s)}
                                onDelete={() => handleDelete(s)}
                                onChange={(values) => handleChange(s, values)}
                                isFirst={i === 0}
                                isLast={i === slides.length - 1}
                            />
                        ))}
                <div>
                    <Upload
                        accept="image/*"
                        name="file"
                        action={`${BaseUrlConstants.BASE_URL}tutorial-slides`}
                        headers={{ 'X-Auth-Token': TokenContainer.get() }}
                        data={() => {
                            return {
                                locale,
                                type: type.value,
                                sortOrder: slides.length,
                                backgroundColor: '#59c1c6',
                            };
                        }}
                        showUploadList={false}
                        onChange={({ file }) => {
                            switch (file.status) {
                                case 'done':
                                    refetch && refetch(file.response);
                                    break;
                                case 'error':
                                    console.error(
                                        'Error',
                                        file.response && file.response.message,
                                    );
                                    break;
                                default:
                                // Nothing
                            }
                        }}
                    >
                        <Button
                            icon={<PlusOutlined />}
                            shape="circle"
                            size="large"
                        />
                    </Upload>
                </div>
            </div>
        </div>
    );
}

function ResetButton({ type }) {
    const [loading, setLoading] = useState(false);
    function handleReset() {
        setLoading(true);
        ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}tutorial-types/reset`,
            method: 'POST',
            data: {
                type,
            },
        })
            .then(() => {
                ToastActions.createToastSuccess('Tutoriel reinitialisé');
            })
            .catch(() =>
                ToastActions.createToastError('Une erreur est survenue'),
            )
            .finally(() => {
                setLoading(false);
            });
    }
    return (
        <Popconfirm
            title="Etes-vous sûr ?"
            onConfirm={handleReset}
            okText={Locale.Popconfirm.okText}
            cancelText={Locale.Popconfirm.cancelText}
        >
            <Button loading={loading} size="small">
                Réinitialiser
            </Button>
        </Popconfirm>
    );
}

function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this,
            args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function Slide({
    slide,
    isFirst,
    isLast,
    onMovePrev,
    onMoveNext,
    onDelete,
    onChange,
}) {
    const handleChange = useCallback(debounce(onChange, 250), []);

    return (
        <div
            className="__slide"
            style={{ backgroundColor: slide.backgroundColor }}
        >
            <div className="__actions">
                {
                    <Button
                        shape="circle"
                        size="small"
                        onClick={onMovePrev}
                        icon={<LeftOutlined />}
                        disabled={isFirst}
                    />
                }
                <input
                    type="color"
                    className="__colorpicker"
                    onChange={(e) => {
                        e.target.value !== slide.backgroundColor &&
                            handleChange({
                                backgroundColor: e.target.value,
                            });
                    }}
                    value={slide.backgroundColor}
                />
                <DeleteButton
                    shape="circle"
                    size="small"
                    onDelete={onDelete}
                    icon={<DeleteOutlined />}
                />
                {
                    <Button
                        shape="circle"
                        size="small"
                        onClick={onMoveNext}
                        icon={<RightOutlined />}
                        disabled={isLast}
                    />
                }
            </div>
            <img className="__image" src={`${slide.data}`} alt="Diapo" />
        </div>
    );
}

function useTutorialTypes() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(undefined);
    useEffect(() => {
        setLoading(true);
        ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}tutorial-types`,
        })
            .then((res) => {
                setData(res);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setLoading(false);
            });
    }, []);
    return { data, loading };
}

function useTutorialSlides(locale, type) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(undefined);

    const refetch = useCallback(() => {
        setLoading(true);
        ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}tutorial-slides?locale=${locale}&type=${type}`,
        })
            .then((res) => {
                setData(res);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setLoading(false);
            });
    }, [locale, type]);
    useEffect(() => {
        refetch();
    }, [refetch]);
    return { data, loading, refetch };
}
