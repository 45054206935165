import { notification } from 'antd';

class ToastActions {
    createToast(
        message: string,
        description: string | null,
        toastType: ToastType,
    ): void {
        notification[toastType]({
            message,
            description,
        });
    }

    createToastError(message: string, description: string | null): void {
        this.createToast(message, description, 'error');
    }
    createToastSuccess(message: string, description: string | null): void {
        this.createToast(message, description, 'success');
    }
}

export default new ToastActions();
