import EntityStoreBase from 'stores/EntityStoreBase';
import HarbourConstants from 'constants/HarbourConstants';

class HarbourStore extends EntityStoreBase<IHarbour> {
    currentHarbourId: number | null | undefined;

    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        const currentHarbourId = localStorage.getItem('currentHarbourId');
        this.currentHarbourId = currentHarbourId
            ? parseInt(currentHarbourId, 10)
            : null;
    }

    _registerToActions = (action: { type: string; payload: any }): void => {
        const { type, payload } = action;
        switch (type) {
            case HarbourConstants.RECEIVE_HARBOURS:
                this._receiveAllEntities(payload.harbours);
                break;
            case HarbourConstants.RECEIVE_HARBOUR:
                this._receiveEntity(payload.harbour);
                if (payload.harbour.id === this.currentHarbourId) {
                    this.emitCurrentHarbourChange();
                }
                break;
            case HarbourConstants.DELETE_HARBOUR:
                this._deleteEntity(payload.id);
                break;

            default:
                // Do Nothing
                break;
        }
    };

    // /// Public Methods  /////

    emitCurrentHarbourChange(): void {
        this.emit('CURRENT_HARBOUR_CHANGE');
    }

    addCurrentHarbourChangeListener(callback: () => void) {
        this.on('CURRENT_HARBOUR_CHANGE', callback);
    }

    removeCurrentHarbourChangeListener(callback: () => void) {
        this.removeListener('CURRENT_HARBOUR_CHANGE', callback);
    }

    /**
     * Get all the harbours.
     */
    getAll = (): Array<IHarbour> => this._entities;

    /**
     * Get a harbour by ID.
     * @param {number} id The identifier.
     * @return {?User}    The harbour, or NULL if no entity is found.
     */
    getById = (id: number): IHarbour | null | undefined =>
        this._entities.find((e) => e.id === id);

    /**
     * Get the identifier of the current harbour.
     * @return {[type]} [description]
     */
    getCurrentHarbourId = (): number | null | undefined =>
        this.currentHarbourId ||
        (this._entities.length ? this._entities[0].id : null);

    /**
     * Get the identifier of the current harbour.
     * @return {[type]} [description]
     */
    getCurrentHarbour = (): IHarbour | null | undefined => {
        const currentId = this.getCurrentHarbourId();
        return currentId ? this.getById(currentId) : null;
    };

    /**
     * Get the identifier of the current harbour.
     * @param {[type]} harbourId [description]
     */
    setCurrentHarbourId = (harbourId: string) => {
        this.currentHarbourId = parseInt(harbourId, 10);
        localStorage.setItem(
            'currentHarbourId',
            this.currentHarbourId.toString(),
        );
        this.emitChange();
        this.emitCurrentHarbourChange();
    };
}

export default new HarbourStore();
