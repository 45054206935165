import React from 'react';
import { Form, Button, Input, Checkbox, Select } from 'antd';
import Locale from 'locale/LocaleFactory';
import CountrySelect from 'components/PhoneInput/CountrySelect';

const MODULES = [
    'weather',
    'webcam',
    'info',
    'absence',
    'security',
    'commitment',
    'rate',
    'communication',
];

export default function HarbourForm({ loading, fields, onChange, onSubmit }) {
    function handleSubmit(values) {
        const harbour = getHarbourFromValues(values);
        onSubmit(harbour);
    }

    function getHarbourFromValues(values) {
        const harbour = {};
        const keys = Object.keys(values);
        const ignoredKeys = [];

        for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            if (!k.endsWith('_ids')) {
                if (k.endsWith('_id')) {
                    const tK = k.replace('_id', '');
                    harbour[tK] = values[k];
                    ignoredKeys.push(tK);
                } else if (ignoredKeys.indexOf(k) === -1) {
                    harbour[k] = values[k];
                }
            }
        }

        return harbour;
    }

    return (
        <Form
            layout="vertical"
            fields={fields}
            onFieldsChange={(_, allFields) => {
                onChange(allFields);
            }}
            onFinish={handleSubmit}
        >
            <Form.Item name="isPartner" valuePropName="checked">
                <Checkbox>Port partenaire</Checkbox>
            </Form.Item>
            <Form.Item name="isPublic" valuePropName="checked">
                <Checkbox>Port public</Checkbox>
            </Form.Item>
            <Form.Item
                name="name"
                label={Locale.trans('harbour.name')}
                rules={[
                    {
                        required: true,
                        message: Locale.trans('error.required'),
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="defaultLocale"
                label={Locale.trans('harbour.defaultLocale')}
                rules={[
                    {
                        required: true,
                        message: Locale.trans('error.required'),
                    },
                ]}
            >
                <Select>
                    {['fr', 'en', 'es', 'it'].map((l) => (
                        <Select.Option key={l} value={l}>
                            {Locale.trans(`locale.${l}`)}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                name="country"
                label={Locale.trans('harbour.country')}
                rules={[
                    {
                        required: true,
                        message: Locale.trans('error.required'),
                    },
                ]}
            >
                <CountrySelect />
            </Form.Item>
            <Form.Item
                name="latitude"
                label={Locale.trans('harbour.latitude')}
                rules={[
                    {
                        required: true,
                        message: Locale.trans('error.required'),
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="longitude"
                label={Locale.trans('harbour.longitude')}
                rules={[
                    {
                        required: true,
                        message: Locale.trans('error.required'),
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item name="address" label={Locale.trans('harbour.address')}>
                <Input.TextArea autosize={{ minRows: 3, maxRows: 50 }} />
            </Form.Item>
            <Form.Item name="phone" label={Locale.trans('harbour.phone')}>
                <Input />
            </Form.Item>
            <Form.Item name="email" label={Locale.trans('harbour.email')}>
                <Input />
            </Form.Item>
            <Form.Item
                name="absenceEmail"
                label={Locale.trans('harbour.absenceEmail')}
            >
                <Input />
            </Form.Item>

            <fieldset>
                <legend>Modules</legend>
                {MODULES.map((module) => (
                    <Form.Item
                        key={module}
                        name={['modules', module]}
                        label={Locale.trans(`harbour.modules.${module}`)}
                    >
                        <ModuleSelect />
                    </Form.Item>
                ))}

                {/* TODO remove after the update */}
                <Form.Item name="showSecurityModule" valuePropName="checked">
                    <Checkbox>Module sécurité</Checkbox>
                </Form.Item>
                <Form.Item name="showCommitmentModule" valuePropName="checked">
                    <Checkbox>Module d'engagement</Checkbox>
                </Form.Item>
                <Form.Item
                    name="sendOutingNotification"
                    valuePropName="checked"
                >
                    <Checkbox>Envoyer les notifications de sortie</Checkbox>
                </Form.Item>
                <Form.Item
                    name="sendOutingNotificationBySMS"
                    valuePropName="checked"
                >
                    <Checkbox>
                        Envoyer les notifications de sortie par SMS
                    </Checkbox>
                </Form.Item>
                {getFieldValue(fields, 'sendOutingNotificationBySMS') && (
                    <Form.Item
                        name="SMSAPIKey"
                        label="Clé d'accès API SMS"
                        rules={[
                            {
                                required: true,
                                message: Locale.trans('error.required'),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                )}
            </fieldset>

            <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
            >
                {Locale.trans('save')}
            </Button>
        </Form>
    );
}

function getFieldValue(fields, name) {
    const field = fields.find(
        (f) => f.name && f.name.length > 0 && f.name[0] === name,
    );
    return field ? field.value : undefined;
}

function ModuleSelect({ value, onChange }) {
    const levels = [0, 1, 2];
    return (
        <Select value={value} onChange={onChange}>
            {levels.map((level) => (
                <Select.Option key={level} value={level}>
                    {Locale.trans(`harbour.modules.levels.${level}`)}
                </Select.Option>
            ))}
        </Select>
    );
}
