import React from 'react';
import { Form, Input, Button } from 'antd';
import Locale from 'locale/LocaleFactory';

export default function ResetPasswordForm({
    loading,
    fields,
    onChange,
    onSubmit,
}) {
    const [form] = Form.useForm();
    function checkPassword(_, value, callback) {
        if (value && value !== form.getFieldValue('plainPassword')) {
            callback('Les mots de passe ne sont pas identiques!');
        } else {
            callback();
        }
    }

    return (
        <Form
            form={form}
            layout="vertical"
            fields={fields}
            onFieldsChange={(_, allFields) => {
                onChange(allFields);
            }}
            onFinish={onSubmit}
        >
            <Form.Item
                name="plainPassword"
                rules={[
                    {
                        required: true,
                        message: Locale.trans('user.password.error.required'),
                    },
                ]}
            >
                <Input
                    type="password"
                    placeholder={Locale.trans('user.password')}
                />
            </Form.Item>
            <Form.Item
                name="passwordConfirm"
                rules={[
                    {
                        validator: checkPassword,
                    },
                ]}
            >
                <Input
                    type="password"
                    placeholder={Locale.trans('user.passwordConfirm')}
                />
            </Form.Item>
            <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
            >
                {Locale.trans('edit')}
            </Button>
        </Form>
    );
}
